<template>
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.27356 2.51473L4.77297 5.21273H13.227L14.7264 2.51473C15.2981 1.48613 14.2307 0.327467 13.1112 0.761338L11.9076 1.22782C11.4284 1.41355 10.881 1.30469 10.5161 0.95108L9.91234 0.366152C9.40847 -0.12205 8.59153 -0.122051 8.08766 0.366151L7.48395 0.95108C7.11899 1.30469 6.57164 1.41355 6.09242 1.22782L4.88878 0.761338C3.76928 0.327467 2.70192 1.48613 3.27356 2.51473Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.14693 11.3741L4.54987 7.08791H13.4501L16.8531 11.3741C17.5968 12.3109 18 13.4595 18 14.6414C18 17.6009 15.5238 20 12.4693 20H5.53066C2.47616 20 0 17.6009 0 14.6414C0 13.4595 0.40323 12.3109 1.14693 11.3741ZM8.18527 14.8634L11.0576 17.6361C11.41 17.9763 11.9801 17.9763 12.3325 17.6361L15.2048 14.8634C15.5582 14.5223 15.5592 13.9682 15.2072 13.6259C14.8551 13.2835 14.2832 13.2825 13.9299 13.6236L12.5982 14.9091V11.4708C12.5982 10.9875 12.1938 10.5957 11.695 10.5957C11.1962 10.5957 10.7919 10.9875 10.7919 11.4708V14.9091L9.46019 13.6236C9.10682 13.2825 8.53497 13.2835 8.18291 13.6259C7.83085 13.9682 7.83191 14.5223 8.18527 14.8634Z"
    />
  </svg>
</template>
